*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  font-family: var(--font-openSans);
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-openSans);
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

.tableRow:nth-child(even) {
  background-color: #f5f6f9;
}

.tableRow:nth-child(odd) {
  background-color: #fff;
}

.tableCell {
  display: table-cell;
  padding: 16px;
  padding: 12px 16px;
  line-height: 21px;
  font-weight: 400;
  font-size: 14px;
}

.checkbox-custom:checked:after,
.checkbox-custom:checked:hover:after {
  content: url("https://asset.inaproc.id/govmart/icons/iconCheckMarkWhite.svg") !important;
}

.checkbox-custom:hover::after {
  content: url("https://asset.inaproc.id/govmart/icons/iconCheckMarkGrey.svg") !important;
}

.checkbox-white-bordered {
  border: 1px solid #818996 !important;
}

.checkbox-white-bordered:checked,
.checkbox-white-bordered:checked:hover,
.checkbox-white-bordered:hover {
  border: 1px solid #fff !important;
  background-color: #2b50c8 !important;
}

label:has(> .checkbox-custom.border-label-0) {
  border-bottom: 0 !important;
  display: flex !important;
  padding: 2px 0 0;
}

.container-textfield:has(.custom-textfield) {
  margin-bottom: 8px !important;
}

.layout_channel-list_provider {
  width: 100% !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.react-pdf__Page__canvas {
  max-width: none !important;
}

@media print {
  * {
    print-color-adjust: exact;
  }

  table {
    page-break-inside: auto;
  }

  thead {
    display: table-header-group;
  }

  td {
    print-color-adjust: exact;
  }

  tr {
    print-color-adjust: exact;
    page-break-inside: avoid;
    page-break-after: auto;
  }
}
